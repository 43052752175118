.navBarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #222;
  padding-top: 0.6vw;
  padding-bottom: 0.6vw;
  position: relative;
  z-index: 3;
}

.navBarTopContainer {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: center;
  justify-content: center;
  width: 95%;
}

.navBarTopCrypto {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.navBarBottomLogo {
  font-weight: 800;
  font-size: 1.1vw;
  color: #0f77b7;
  text-decoration: none;
  text-align: left;
}

.navBarMenuContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navBarMenu {
  background-color: #222;
  width: 270px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 100%;
  transition: 850ms;
}

.navBarMenu.active {
  left: calc(100% - 270px);
  transition: 350ms;
}

.navBarMenuText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 8px 0px;
  list-style: none;
}

.navBarMenuText a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 17px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.navBarMenuText img {
  width: 1em;
  height: 1em;
}

.navBarMenuLinkNotActive {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  cursor: pointer;
}

.navBarMenuText a:hover {
  background-color: #1a83ff;
}

.navBarMenuLinkNotActive:hover {
  background-color: #1a83ff;
}

.navBarMenuItems {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.navBarMenuToggle {
  background-color: #222;
  width: 100%;
  height: 3vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navBarMenuItems span {
  margin-left: 16px;
}

.navBarMenuBars {
  display: none;
  align-items: center;
  justify-content: center;

  margin-right: 2rem;
  border: none;
  outline: none;
  background: transparent;
}

.navBarMenuContainer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 4;
}

.navBarMenuContainer.active {
  opacity: 1;
  pointer-events: all;
}

.right {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.menu p {
  font-weight: 700;
  font-size: 1.15vw;
  color: var(--general-light-a);
  text-transform: uppercase;
  padding-right: 1vw;
}

.hamburger {
  --line-height: 2px;
  --hamburger-height: 1.5vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 2vw;
  height: var(--hamburger-height);
  z-index: 5;
}

.hamburger .line {
  display: block;
  width: 100%;
  height: var(--line-height);
  background-color: var(--general-light-a);
  border-radius: 10px;
  transition: all 0.25s ease-in-out;
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(1) {
  transform: translateY(
      calc((var(--hamburger-height) / 2) - (var(--line-height) / 2))
    )
    rotate(45deg);
}

.hamburger.open .line:nth-child(3) {
  transform: translateY(
      calc((var(--hamburger-height) / -2) - (var(--line-height) / -2))
    )
    rotate(-45deg);
}

@media only screen and (max-width: 1280px) {
  .navBarMenuBars {
    display: flex;
  }
  .hamburger {
    --line-height: 1px;
    z-index: 4;
  }
  .navBarMenuToggle {
    height: 5vw;
  }
}

@media only screen and (max-width: 800px) {
  .navBarMenuToggle {
    height: 8vw;
  }
}

@media only screen and (max-width: 560px) {
  .navBarMenuToggle {
    height: 14vw;
  }
  .navBarMenu {
    width: 100vw;
  }
  .navBarMenu.active {
    left: 0;
  }
  .navBarMenuText {
    margin-top: 1vw;
  }
  .navBarMenuText a {
    font-size: 6.5vw;
  }
  .navBarMenuLinkNotActive {
    font-size: 7vw;
  }
}
