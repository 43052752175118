.navBarTopCryptoElementContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f2f2f2;
}

.navBarTopCryptoElementWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 0.5vw;
}

.navBarTopCryptoElementContainer > img {
  border-style: none;
  width: 1.2vw;
  height: 1.2vw;
}

.navBarTopCryptoElementPrices {
  display: flex;
}

.navBarTopCryptoElementPercent {
  display: flex;
  margin-left: 0.4vw;
  align-items: center;
}

.navBarTopCryptoElementName {
  font-size: 0.8vw;
  font-weight: 600;
}

.navBarTopCryptoElementPricesText {
  font-size: 0.675vw;
}

.navBarTopCryptoElementPercentGreen {
  color: var(--success);
}

.navBarTopCryptoElementPercentRed {
  color: var(--error);
}

.navBarTopCryptoElementPercentArrow {
  width: 0.84vw;
  height: 100%;
}
