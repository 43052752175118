.profitabilityContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.profitabilityContentWrapper {
  display: flex;
  justify-content: space-between;
  width: clamp(1280px, 72vw, 72vw);
  margin-top: 3vw;
}

.profitabilityH1 {
  font-size: clamp(1.5rem, 1.58vw, 1.58vw);
  font-weight: 700;
}

.profitabilityLabel {
  font-weight: 700;
}

.profitabilityInputContainer {
  display: flex;
  align-items: center;
}

.profitabilityInputContainer label {
  margin: 0;
  font-size: clamp(0.875rem, 0.8vw, 0.8vw);
}

.profitabilityInput {
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
  transition: 0.15s;
  outline: none;
  height: clamp(calc(1.5em + 0.5rem), 1.5vw, 1.5vw);
  padding: 0.25vw 0.5vw;
  width: 7.5vw;
  font-size: clamp(0.765625rem, 0.65vw, 0.65vw);
  z-index: 1;
}

.profitabilityInput:focus {
  box-shadow: 0 0 5px 3px rgba(69, 123, 157, 0.3);
}

.profitabilityInputUnitsWrapper {
  margin-left: -1px;
  height: 100%;
  display: flex;
  align-items: center;
}

.profitabilityInputComponentContainer {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 0.5vw;
  color: #212529;
}

.profitabilityInputUnits {
  font-size: clamp(0.765625rem, 0.65vw, 0.65vw);
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  height: clamp(calc(1.5em + 0.5rem), 1.5vw, 1.5vw);
  border-radius: 0 0.2rem 0.2rem 0;
  display: flex;
  align-items: center;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

@media only screen and (max-width: 1280px) {
  .profitabilityH1 {
    font-size: 2.3vw;
  }
  .profitabilityInputContainer label {
    font-size: 1.4vw;
  }
  .profitabilityContentWrapper {
    width: 98%;
  }
  .profitabilityInputUnits,
  .profitabilityInput {
    height: 2.3vw;
    font-size: 1vw;
  }
  .profitabilityInput {
    border-radius: 0.13rem;
    width: 11.5vw;
  }
  .profitabilityInputUnits {
    border-radius: 0 0.13rem 0.13rem 0;
  }
}

@media only screen and (max-width: 800px) {
  .profitabilityInput:focus {
    box-shadow: 0 0 2px 1px rgba(69, 123, 157, 0.3);
  }
  .profitabilityInputUnits,
  .profitabilityInput {
    height: 2.7vw;
  }
}
