.asicTableContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.asicTable {
  width: clamp(1280px, 72vw, 72vw);
  color: #212529;
  border-collapse: collapse;
  margin-top: 0.5vw;
  margin-bottom: 1vw;
}

.asicTable th {
  font-size: clamp(0.8rem, 0.75vw, 0.75vw);
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  padding: 0.3vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  cursor: pointer;
  white-space: nowrap;
}

.asicTable th:hover {
  background-color: #f5f5f5;
}

.asicTableSelectable {
  color: #337ab7;
}

@media only screen and (max-width: 1280px) {
  .asicTable {
    width: 98%;
  }
  .asicTable th {
    font-size: 1vw;
  }
}
