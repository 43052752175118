.block {
  flex: 1 1;
}

.error {
  display: flex;
  align-items: center;

  height: 45px;

  font-weight: 400;
  font-size: var(--text-size-big);
  color: var(--general-dark-a);
}

.error p {
  font-weight: 400;
  font-size: var(--text-size-big);
  color: var(--general-dark-a);
}

.error p.code {
  font-weight: 500;
  font-size: var(--text-size-heading-a);
}

.line {
  width: 1px;
  height: 100%;
  background: var(--general-light-c);
  margin: 0px 12px 0px 12px;
}
