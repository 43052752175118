.advertisingContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 1.74vw;
}

.advertisingImgWrapper {
  max-height: 10vw;
  max-width: 80vw;
}

.advertisingImgWrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.advertisingImgWrapper img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}
