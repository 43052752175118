.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1vw;
  background-color: var(--header-footer-bg);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.adminNavBar {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
  column-gap: 2vw;
  margin-top: 0.6vw;
  margin-bottom: 0.6vw;
}

.adminNavBarLink {
  text-decoration: none;
}

.adminNavBarButton {
  outline: none;
  font-size: clamp(12px, 0.8vw, 0.8vw);
  cursor: pointer;
  text-align: center;
  background-color: rgb(77, 77, 77);
  color: #fff;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.adminNavBarLink.active {
  pointer-events: none;
}

.adminNavBarLink.active .adminNavBarButton {
  background-color: rgba(77, 77, 77, 0.8);
  color: rgba(225, 225, 225, 0.8);
}

@media only screen and (max-width: 560px) {
  .adminNavBar {
    margin-top: 1vw;
    margin-bottom: 1vw;
  }

  .adminNavBarButton {
    font-size: 2vw;
    padding: 1.5vw 4vw;
  }
}
