.wrapper {
  flex-direction: column;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 8px;
}

.buttons {
  display: flex;
  height: 28px;
  column-gap: 5px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 0px 9px 0px 9px;
  border-radius: 4px;

  font-weight: 400;
  font-size: 14px;
  color: var(--general-light-a);

  border: 1px solid transparent;
  outline: none;
  background: transparent;
  cursor: pointer;

  transition: 0.25s ease-in-out;
}

.btn.green {
  background: var(--general-green-a);
}

.btn.green:hover {
  background: var(--general-green-b);
}

.btn.red {
  background: var(--general-red-a);
}

.btn.red:hover {
  background: var(--general-red-b);
}

.btn.disabled {
  border-color: var(--secondary-dark-a);
  background: transparent;
  color: var(--general-dark-a);
}

.btn.disabled:hover {
  background: var(--secondary-dark-a);
  color: var(--general-light-a);
}

@media only screen and (max-width: 1281px) {
  .controls {
    margin-top: 8px;
    margin-bottom: 6px;
  }

  .buttons {
    height: 24px;
    column-gap: 4px;
  }

  .btn {
    padding: 0px 7px 0px 7px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1024px) {
  .controls {
    margin-top: 6px;
    margin-bottom: 4px;
  }

  .buttons {
    height: 19px;
    column-gap: 3px;
  }

  .btn {
    padding: 0px 5px 0px 5px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .controls {
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .buttons {
    height: 12px;
  }

  .btn {
    padding: 0px 2px 0px 2px;
    font-size: 6px;
    border-radius: 2px;
  }
}

@media only screen and (max-width: 480px) {
  .buttons {
    height: 11px;
    column-gap: 2px;
  }

  .btn {
    font-size: 5px;
  }
}

@media only screen and (max-width: 320px) {
  .buttons {
    height: 9px;
  }

  .btn {
    font-size: 4px;
  }
}
