.brand {
  font-weight: 700;
  color: var(--general-blue-a);
  margin-right: 0.3vw;
}

.brand.nvidia {
  color: var(--general-green-a);
}

.brand.amd {
  color: var(--general-red-a);
}

.power {
  font-weight: 300;
  color: var(--secondary-dark-a);
  font-size: 0.875em;
}

.power.selected {
  font-weight: 400;
}
