.gratitudeContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.gratitudeText {
  font-size: clamp(1.8rem, 1.5vw, 1.5vw);
  font-weight: 600;
  background: green;
  color: white;
  padding: 1.5vw;
}

.gratitudeImg {
  height: 75.3vh;
}

.gratitudeLink {
  text-decoration: none;
  font-weight: 400;
  width: 30vw;
  border: 1px solid black;
  padding: 0.375rem 0.75rem;
  outline: none;
  font-size: clamp(0.94rem, 0.84vw, 0.84vw);
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: white;
  color: black;
  text-align: center;
  margin-bottom: 5vh;
}

@media only screen and (max-width: 800px) {
  .gratitudeText {
    font-size: 3.7vw;
  }
  .gratitudeImg {
    height: auto;
    max-height: 80vh;
    max-width: 80vw;
  }
  .gratitudeContainer {
    justify-content: center;
  }
  .gratitudeLink {
    font-size: 3vw;
    width: 50vw;
  }
}

@media only screen and (max-width: 560px) {
  .gratitudeLink {
    font-size: 3vw;
    width: 65vw;
  }
}
