.adminStatTablesWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
}

.adminStatTableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}

.adminStatTableContainer.child {
  width: 100%;
}

.adminStatTable {
  width: 100%;
  border-collapse: collapse;
}

.adminStatTable th {
  font-size: clamp(0.8rem, 0.75vw, 0.75vw);
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
  padding: 0.3vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  cursor: pointer;
}

.adminStatTable th:hover {
  background-color: #f5f5f5;
}

.adminStatTable td {
  padding: 0.3vw;
  padding-top: 0.7vw;
  padding-bottom: 0.7vw;
  border-top: 1px solid #dee2e6;
  vertical-align: middle;
}

.adminStatPeriodContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  font-size: clamp(0.85rem, 0.9vw, 0.9vw);
}

.adminStatPeriodButton {
  background-color: rgb(128, 128, 128);
  margin: 0.5rem;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.adminStatPeriodButton.active {
  background-color: #7c9fff;
}

.adminStatAllStatContainer {
  display: flex;
  justify-content: center;
  margin-top: 3vw;
  margin-bottom: 5vw;
  color: white;
}

.adminStatTableContainer h1 {
  font-size: clamp(1.5rem, 1.58vw, 1.58vw);
}

.adminStatAllStat {
  background-color: #4073ff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: clamp(0.85rem, 1vw, 1vw);
}

.adminStatTableRow {
  color: #212529;
  font-size: clamp(0.875rem, 0.8vw, 0.8vw);
}

.adminStatTableRow.selected {
  font-weight: 700;
  background-color: #c3e6cb !important;
}

.adminStatTableRow:hover {
  background-color: #f5f5f5;
}

.adminStatTextCenter {
  text-align: center;
}

.adminStatTextCenter.clickable {
  color: #337ab7;
}

.adminStatAllStat span {
  font-weight: 700;
}

@media only screen and (max-width: 1280px) {
  .adminStatTable th {
    font-size: 1vw;
  }
  .adminStatTableRow {
    font-size: 1.3vw;
  }
  .adminStatPeriodContainer {
    font-size: 1.1vw;
  }
  .adminStatAllStat {
    font-size: 1.1vw;
  }
  .adminStatTableContainer h1 {
    font-size: 2.3vw;
  }
}

@media only screen and (max-width: 560px) {
  .adminStatTablesWrapper {
    flex-direction: column;
    align-items: center;
  }
  .adminStatTableContainer {
    width: 90%;
    margin-top: 2vw;
  }
  .adminStatTableContainer.child:not(.margin-top-2vw) {
    margin-top: 0;
  }
  .adminStatTableRow {
    font-size: 2.2vw;
  }
  .adminStatTable th {
    font-size: 2vw;
  }
  .adminStatTableContainer h1 {
    font-size: 2.7vw;
  }
  .adminStatTextCenter {
    text-align: right;
  }
  .adminStatAllStat {
    font-size: 3vw;
  }
  .adminStatPeriodContainer {
    font-size: 2vw;
  }
  .adminStatPeriodButton {
    padding: 1.5vw 2vw;
  }
}
