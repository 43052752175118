.asicRowNameWrapper {
  display: flex;
  align-items: center;
}

.asicRowImg {
  width: 2.05vw;
  height: 2.05vw;
  margin-right: 0.25vw;
}

.asicRowName {
  color: #337ab7;
}

.asicRowPrice {
  font-weight: 600;
}

.asicRow {
  color: #212529;
  font-size: clamp(0.875rem, 0.8vw, 0.8vw);
}

.asicRow:hover {
  background-color: #f5f5f5;
}

.asicRow td {
  padding: 0.3vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-top: 1px solid #dee2e6;
  vertical-align: middle;
  white-space: nowrap;
}

.asicRowButton {
  display: flex;
  justify-content: center;
  font-size: clamp(0.78rem, 0.7vw, 0.7vw);
  width: 5vw;
  height: 1.5vw;
  align-items: center;
  border-radius: 1rem;
  color: white;
  background: green;
  cursor: pointer;
}

.asicRowButtonInactive {
  background: #868686;
  pointer-events: none;
}

.asicRowSelected {
  font-weight: 700;
  background-color: #c3e6cb !important;
}

.asicRowInactive {
  background-color: #f0f0f0;
}

@media only screen and (max-width: 1280px) {
  .asicRow {
    font-size: 1.2vw;
  }
  .asicRowButton {
    font-size: 0.7vw;
    width: 5vw;
    height: 1.75vw;
  }
  .asicRowImg {
    width: 3vw;
    height: 3vw;
  }
}
