.DotLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.DotLoader h1 {
  font-weight: 400;
  font-size: var(--text-size-heading-a);
  color: var(--general-dark-a);
}
