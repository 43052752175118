.loginContainer {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginForm-group {
  margin-bottom: 1rem;
}

.loginForm-control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  outline: none;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.loginForm-control:focus {
  border: 1px solid black;
  box-shadow: 0 0 0 1px black;
}

.loginBtn {
  font-weight: 400;
  width: 100%;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  outline: none;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
}

.loginBtn-black {
  background-color: #000;
  color: #fff;
}
