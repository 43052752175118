.adminIngPanel {
  width: 100%;
  height: 100%;
}

.adminIngPanelInputContainer {
  display: flex;
  justify-content: center;
  margin-top: 2vw;
}

.adminIngPanelInputWrapper {
  display: flex;
  align-items: center;
}

.adminIngPanelImgContainer {
  margin-top: 2vw;
  margin-bottom: 2vw;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adminIngPanelImgWrapper {
  display: flex;
  margin-top: 2vw;
}

.adminIngPanelImgWrapper img {
  max-width: 80vw;
  max-height: 10vw;
}

.adminIngPanelBtnWrapper {
  display: flex;
  align-items: center;
  margin-left: 1vw;
}

.adminIngPanelBtn {
  outline: none;
  font-size: clamp(12px, 0.8vw, 0.8vw);
  background: #3a2b58;
  cursor: pointer;
  text-align: center;
  background-color: rgb(77, 77, 77);
  color: #fff;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

@media only screen and (max-width: 560px) {
  .adminIngPanelBtn {
    font-size: 3vw;
    padding: 1vw 2vw;
  }
}
