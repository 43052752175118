.panelContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.panelTable {
  width: clamp(1280px, 72vw, 72vw);
  border-collapse: collapse;
}

.panelTable th {
  font-size: clamp(0.8rem, 0.75vw, 0.75vw);
}

.panelTable td {
  padding-top: 1px;
  padding-bottom: 1px;
}

.adminAsicRow {
  font-size: clamp(0.875rem, 0.8vw, 0.8vw);
}

.adminAsicRow input {
  font-size: clamp(0.875rem, 0.8vw, 0.8vw);
}

.adminAsicRow.outdated {
  background: var(--error-15);
}

.panelButtonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.panelBtn {
  font-weight: 400;
  width: 25%;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  outline: none;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-align: center;
  cursor: pointer;
}

.panelBtn-black {
  background-color: #000 !important;
  color: #fff;
}

@media only screen and (max-width: 1280px) {
  .panelTable {
    width: 98%;
  }
  .panelTable th {
    font-size: 1vw;
  }
  .adminAsicRow {
    font-size: 1.3vw;
  }
  .adminAsicRow input {
    font-size: 1.3vw;
  }
  .panelButtonWrapper {
    margin-top: 2vw;
    margin-bottom: 3vw;
  }
  .panelBtn {
    font-size: 1.5vw;
    padding: 0.8vw 3vw;
  }
}

@media only screen and (max-width: 800px) {
  .panelButtonWrapper {
    margin-top: 3vw;
    margin-bottom: 4vw;
  }
  .panelBtn {
    font-size: 2.5vw;
    padding: 0.8vw 3vw;
  }
}
